<template>
  <div>
    <div class="header-index-bg" @mouseover="handleB">
      <div class="header-index-top clear">
        <div
          class="header-index-top-left"
          @click="goHome"
          style="cursor: pointer"
        >
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/header/header_logo.png"
            alt=""
          />
        </div>
        <div
          class="header-index-top-rightBtn"
          @click="$router.push({ name: 'culture_interact' })"
          v-if="!isToSearch"
        >
          互动平台
        </div>
        <div class="header-index-top-right" v-if="!isToSearch">
          <div class="header-index-top-right-search">
            <div class="header-index-top-right-search-left">
              <img
                class="header-index-top-right-search-right-icon"
                src="https://image.bookgo.com.cn/webculture/jm/search-glass.png"
                alt
              />
              <input
                class="header-index-top-right-search-left-input"
                placeholder="请输入您感兴趣的内容"
                type="text"
                v-model.trim="searchText"
                @keyup.enter="goToSearch"
              />
              <div
                class="header-index-top-right-search-right-btn"
                @click="goToSearch"
              >
                搜索
              </div>
            </div>
          </div>
          <div class="header-index-top-right-login" v-if="false">登录</div>
        </div>
      </div>
    </div>
    <div class="header-index-bottom-bg">
      <div class="header-index-bottom">
        <div class="header-index-bottom-nav">
          <div
            class="header-index-bottom-nav-a"
            :class="
              isSelected(item) ? 'header-index-bottom-nav-a-selected' : ''
            "
            :to="item.path ? item.path : '/homepage'"
            v-for="(item, index) in navList"
            :key="index"
            @click="click(item)"
          >
            <div
              class="header-index-bottom-nav-a-detail clear"
              :class="isSelected(item) ? 'active-nav-detail' : ''"
              @mouseover="
                handleA(item);
                current = index;
              "
              @mouseleave="
                handleB(item);
                current = index;
              "
            >
              <div class="index-nav-detail-name">{{ item.name }}</div>
            </div>
            <div
              class="index-nav-detail-active-live"
              v-if="isSelected(item)"
            ></div>
            <div
              class="slideDown"
              v-show="current == index && item.children"
              :style="a"
              @mouseleave="handleB(item)"
              @mouseover="handleA(item)"
            >
              <ul>
                <li
                  v-for="(_item, _i) in item.children"
                  :key="_i"
                  @mouseover="curr = _i"
                  @click.stop="click1(_item,item)"
                >
                  <img
                    src="https://image.bookgo.com.cn/webculture/jm/header-selectedpic.png"
                    alt=""
                    v-if="curr == _i"
                  />
                  <div class="slideDown-item">
                    {{ _item.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWechatLoginUrl } from "@/utils/login.js";
import { logout } from "@/utils/auth.js";
// import { mapState } from "vuex";
import { navList } from "@/api/header/header";
export default {
  name: "",
  data() {
    return {
      searchText: "",
      current: -1,
      curr: -1,
      navList: [],
      a: {
        width: "1rem",
        opacity: "0",
        transition: "all,0.3s",
        height: "auto",
      },
      slideList: [],
      isToSearch: false,
      aid: process.env.VUE_APP_AID,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/search") {
        this.isToSearch = true;
      } else {
        this.isToSearch = false;
      }
      this.getNavList()
    },
  },
  created() {
    if (this.$route.path == "/search") {
      this.isToSearch = true;
    }
    this.getNavList();
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/homepage" });
    },
    goToSearch() {
      this.$emit("getSearchText", this.searchText);
      if (this.searchText.length !== 0) {
        this.$router.push({ path: "/search" });
        this.searchText = "";
      }
    },
    isSelected(item) {
      var temp = new URLSearchParams(location.search);
      //console.log('location.search',temp)
      var id = temp.get("id");
      if(id==item.id){
        return true;
      }
      return false;
    },
    getQueryString(key, url) {
      var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
      var result = url.substr(1).match(reg);
      return result ? decodeURIComponent(result[2]) : null;
    },
    goLogin() {
      const loginUrl = getWechatLoginUrl(this.$route.fullPath);
      window.location.href = loginUrl;
    },
    logout() {
      logout();
      window.location.href = "/";
    },
    getNavList() {
      navList({ aid: this.aid }).then((res) => {
        // console.log(res)
        if (res.code == 0) {
          this.navList = res.data.list;
          console.log("this.navList", this.navList);
        }
      });
    },
    click(item) {
      console.log("click", item);
      if (item.children) {
        console.log(1)
        var flag = 1;
        item.children.forEach((i) => {
          if (flag == 1 && i.url_type == 2) {
            flag = 0;
            if (i.content_id == 0) {
              console.log(3)
              this.$router.push(
                `/${i.tpl_code}?id=${item.id}&cid=${i.id}&column_id=${i.column_id}`
              );
              // let routeUrl = this.$router.resolve({
              //   path: `/${i.tpl_code}`,
              //   query: {
              //     id: item.id,
              //     cid: i.id,
              //     column_id: i.column_id,
              //   },
              // });
              // window.open(routeUrl.href, "_blank");
            } else {
              console.log(4)
              this.$router.push(
                `/${i.tpl_code}/detail?tid=${i.content_id}&id=${item.id}&cid=${i.id}&column_id=${i.column_id}`
              );
              // let routeUrl = this.$router.resolve({
              //   path: `/${i.tpl_code}/detail`,
              //   query: {
              //     tid: i.content_id,
              //     id: item.id,
              //     cid: i.id,
              //     column_id: i.column_id,
              //   },
              // });
              // window.open(routeUrl.href, "_blank");
            }
          }
        });
        if (flag == 1) {
          let routeUrl = item.children[0].url;
          window.open(routeUrl, "_blank");
        }
      }else{
        console.log(2)
        if(item.url_type==1){
          let routeUrl = item.url;
          window.open(routeUrl, "_blank");
        }else if(item.url_type==2){
          if(item.content_id==0){
            this.$router.push(
                `/${item.tpl_code}?id=${item.id}&cid=&column_id=${item.column_id}`
              );
            // let routeUrl = this.$router.resolve({
            //   path: `/${item.tpl_code}`,
            //   query: {
            //     id: item.id,
            //     cid: "",
            //     column_id: item.column_id,
            //   },
            // });
            // window.open(routeUrl.href, "_blank");
          }else{
            this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${item.id}&cid=&column_id=${item.column_id}`
              );
            // let routeUrl = this.$router.resolve({
            //   path: `/${item.tpl_code}/detail`,
            //   query: {
            //     tid: item.content_id,
            //     id: item.id,
            //     cid: "",
            //     column_id: item.column_id,
            //   },
            // });
            // window.open(routeUrl.href, "_blank");
          }
        }else{
          alert("配置错误");
        }
      }
    },
    click1(item,parentItem) {
      console.log("click1", item,parentItem);
      if(item.url_type==1){
        let routeUrl = item.url;
        window.open(routeUrl, "_blank");
      }else if(item.url_type==2){
        if(item.content_id==0){
          this.$router.push(
                `/${item.tpl_code}?id=${parentItem.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //     path: `/${item.tpl_code}`,
          //     query: {
          //       id: parentItem.id,
          //       cid: item.id,
          //       column_id: item.column_id,
          //     },
          //   });
            // window.open(routeUrl.href, "_blank");
        }else{
          this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${parentItem.id}&cid=${item.id}&column_id=${item.column_id}`
              );
            // let routeUrl = this.$router.resolve({
            //   path: `/${item.tpl_code}/detail`,
            //   query: {
            //     tid: item.content_id,
            //     id: parentItem.id,
            //     cid: item.id,
            //     column_id: item.column_id,
            //   },
            // });
            // window.open(routeUrl.href, "_blank");
        }
      }else{
        alert("配置错误");
      }
    },
    handleA(item) {
      this.$data.a.height = "auto";
      this.$data.a.opacity = "1";
      this.$data.a.visibility = "visible";
    },
    handleB(item) {
      this.$data.a.visibility = "hidden";
      // this.$data.a.height = "0";
      this.$data.a.opacity = "0";
      this.curr = -1;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/header.scss";
</style>
