var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-index-bg"},[_c('div',{staticClass:"footer-index"},[_c('div',{staticClass:"footer-index-top"},[_c('div',{staticClass:"footer-index-top-link"},[_vm._m(0),_c('ul',{staticClass:"footer-index-top-link-ul"},[_c('li',{staticClass:"footer-index-top-link-ul-li clear"},[_vm._l((_vm.flink_list),function(item,index){return [_c('div',{staticClass:"footer-index-top-link-ul-li-link",style:({
                  'line-height': '.26rem',
                  border: index + 1 == _vm.flink_list.length ? 'none' : '',
                })},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.title))])])]})],2)])]),_vm._m(1),_vm._m(2)]),_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-index-top-link-top clear"},[_c('div',{staticClass:"footer-index-top-link-top-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/web_culture/footer/footer_link.png","alt":""}})]),_c('div',{staticClass:"footer-index-top-link-top-title"},[_vm._v("友情链接")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-index-top-contact"},[_c('div',{staticClass:"footer-index-top-contact-top"},[_c('div',{staticClass:"footer-index-top-contact-top-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/web_culture/footer/footer_contact.png","alt":""}})]),_c('div',{staticClass:"footer-index-top-contact-top-title"},[_vm._v("联系我们")])]),_c('ul',{staticClass:"footer-index-top-contact-ul"},[_c('li',{staticClass:"footer-index-top-contact-ul-li"},[_vm._v(" 地址：荆门市象山一路九号 ")]),_c('li',{staticClass:"footer-index-top-contact-ul-li"},[_vm._v("电话：0724-2332328")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-index-top-attention"},[_c('div',{staticClass:"footer-index-top-attention-top"},[_c('div',{staticClass:"footer-index-top-attention-top-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/web_culture/footer/footer_attention_icon.png","alt":""}})]),_c('div',{staticClass:"footer-index-top-attention-top-title"},[_vm._v("关注我们")])]),_c('ul',{staticClass:"footer-index-top-attention-ul"},[_c('li',{staticClass:"footer-index-top-attention-ul-li"},[_c('img',{staticStyle:{"width":"0.88rem","height":"0.88rem"},attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/footer/footer-index-followus.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-index-bottom"},[_c('div',{staticClass:"jmqyg"},[_vm._v("版权所有：荆门市群众艺术馆")]),_c('span',[_vm._v("Copyright@http://jmsqyg.cn all rights reserved")]),_c('div',{staticClass:"divLine"}),_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"_blank"}},[_vm._v("鄂ICP备11017664号-2")])])
}]

export { render, staticRenderFns }