import Vue from 'vue'
import Storage from 'vue-ls';


const options = {
  namespace: '', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
};

Vue.use(Storage, options);

const tokenKey = 'userToken'

export function getToken() {
  return Vue.ls.get(tokenKey);
}

export async function cleanToken() {
  Vue.ls.remove(tokenKey); // 移除

}

export function setToken(token) {
  Vue.ls.set(tokenKey, token, 60 * 60 * 12000); //有效12小时
}

const userInfo = "userInfo_key"
export function setUserInfo(data) {
  Vue.ls.set(userInfo, data, 60 * 60 * 12000); //有效12小时
}
export function logout() {
  cleanToken()
  Vue.ls.remove(userInfo);
}

/**
 * 用户信息
 * 
 * bind_face: 0
  birthday: "0000-00-00"
  headimgurl: "http://thirdwx.qlogo.cn/mmopen/vi_32/nHTeBrP98wwUnCahia8qsAKcHUhawhIcNmhhyBGEPKm8J21v8HPLOxezuTGCzcHd8V4nAoqrFTnydcApmAEG7Kg/132"
  id: 69724
  id_card: null
  mobile_number: null
  nickname: "ly"
  realname: null
  sex: 1
  sex_text: "男"
 */
export function getUserInfo() {
  return Vue.ls.get(userInfo);
}

