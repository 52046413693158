// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils'


/**
 * 替换html标签
 * @param tagStr
 * @returns {*}
 */
export function deleteHtmlTag(tagStr) {
    if(tagStr=='' || tagStr==undefined){
        return '';
    }
    let regx = /<[^>]*>|<\/[^>]*>/gm;
    let result = tagStr.toString().replace(regx,"");
    return result;
}