import Vue from 'vue'
import VueRouter from 'vue-router'
import Base from '../views/layouts/Base.vue'
import { getPageSid, B } from "../static/xs_culture/js/mta_analysis";
var public_base = process.env.VUE_APP_PUBLIC_BASE;
var aid = process.env.VUE_APP_AID;
var routes = []
Vue.use(VueRouter)

routes = [{
    path: '/',
    name: 'web_culture',
    // redirect: '/culture',
    redirect: "/homepage",
    component: Base,
    children: [{
        path: "homepage",
        name: "culture_homepage",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/homepage/index.vue")
    }, {
        path: "activity",
        name: "culture_activity",
        meta: {
            active: 'culture_activity',
            title: "群文活动-荆门市群众艺术馆"
        },
        component: () => import("../views/activity/index.vue")
    }, {
        path: "activity/detail",
        meta: {
            active: 'culture_activity',
            title: "群文活动-荆门市群众艺术馆"
        },
        name: "culture_activity_detail",
        component: () => import("../views/activity/detail.vue")
    },
        {
            path: "offlinetrain",
            name: "offlinetrain",
            meta: {
                active: 'culture_offlinetrain',
                title: "艺术培训-荆门市群众艺术馆"
            },
            component: () => import("../views/offlinetrain/index.vue")
        }, {
            path: "offlinetrain/detail",
            meta: {
                active: 'culture_offlinetrain',
                title: "艺术培训-荆门市群众艺术馆"
            },
            name: "offlinetrain_detail",
            component: () => import("../views/offlinetrain/detail.vue")
        },
    // {
    //     path: "order",
    //     meta: {
    //         active: 'culture_order',
    //         title: "文化点单-荆门市群众艺术馆"
    //     },
    //     name: "culture_order",
    //     component: () => import("../views/order/index.vue")
    // }, {
    //     path: "order/detail",
    //     meta: {
    //         active: 'culture_order',
    //         title: "文化点单-荆门市群众艺术馆"
    //     },
    //     name: "culture_order_detail",
    //     component: () => import("../views/order/detail.vue")
    // },
    {
        path: "volunteer",
        meta: {
            active: 'culture_volunteer',
            title: "志愿服务-荆门市群众艺术馆"
        },
        name: "culture_volunteer",
        component: () => import("../views/volunteer/index.vue")
    }, {
        path: "volunteer/detail",
        meta: {
            active: 'culture_volunteer',
            title: "志愿服务-荆门市群众艺术馆"
        },
        name: "culture_volunteer_detail",
        component: () => import("../views/volunteer/detail.vue")
    },
    // {
    //     path: "organization",
    //     meta: {
    //         active: 'culture_organization',
    //         title: "文化团队-荆门市群众艺术馆"
    //     },
    //     name: "culture_organization",
    //     component: () => import("../views/organization/index.vue")
    // }, {
    //     path: "organization/detail",
    //     meta: {
    //         active: 'culture_organization',
    //         title: "文化团队-荆门市群众艺术馆"
    //     },
    //     name: "culture_organization_detail",
    //     component: () => import("../views/organization/detail.vue")
    // },
    {
        path: "live",
        meta: {
            active: 'culture_live',
            title: "文化直播-荆门市群众艺术馆"
        },
        name: "culture_live",
        component: () => import("../views/live/index.vue")
    }, {
        path: "live/detail",
        meta: {
            active: 'culture_live',
            title: "文化直播-荆门市群众艺术馆"
        },
        name: "culture_live_detail",
        component: () => import("../views/live/detail.vue")
    },
    //  {
    //     path: "calendar",
    //     meta: {
    //         active: 'culture_calendar',
    //         title: "文化日历-荆门市群众艺术馆"
    //     },
    //     name: "culture_calendar",
    //     component: () => import("../views/calendar/index.vue")
    // },
    // {
    //     path: "map",
    //     name: "culture_map",
    //     meta: {
    //         active: 'culture_map',
    //         title: "文旅地图-荆门市群众艺术馆"
    //     },
    //     component: () => import("../views/map/map.vue")
    // },
    {
        path: "map/list",
        meta: {
            active: 'culture_map',
            title: "场馆预定-荆门市群众艺术馆"
        },
        name: "culture_map",
        component: () => import("../views/map/index.vue")
    },
    {
        path: "map/detail",
        name: "culture_map_detail",
        meta: {
            active: 'culture_map',
            title: "场馆预定-荆门市群众艺术馆"
        },
        component: () => import("../views/map/detail.vue")
    },
    {
        path: "news",
        name: "culture_news",
        meta: {
            active: 'culture_news',
            title: "资讯动态-荆门市群众艺术馆"
        },
        component: () => import("../views/news/index1.vue")
    }, {
        path: "news/detail",
        meta: {
            active: 'culture_news',
            title: "资讯动态-荆门市群众艺术馆"
        },
        name: "culture_news_detail",
        component: () => import("../views/news/detail.vue")
    }, {
        path: "resources",
        meta: {
            active: 'culture_resources',
            title: "数字资源-荆门市群众艺术馆"
        },
        name: "culture_resources",
        component: () => import("../views/resources/index.vue")
    }, {
        path: "resources/edetail",
        meta: {
            active: 'culture_resources',
            title: "数字资源-荆门市群众艺术馆"
        },
        name: "culture_resources_edetail",
        component: () => import("../views/resources/edetail.vue")
    }, {
        path: "resources/tdetail",
        meta: {
            active: 'culture_resources',
            title: "数字资源-荆门市群众艺术馆"
        },
        name: "culture_resources_tdetail",
        component: () => import("../views/resources/tdetail.vue")
    }, {
        path: "resources/bookdetail",
        meta: {
            active: 'culture_resources',
            title: "数字资源-荆门市群众艺术馆"
        },
        name: "culture_resources_bookdetail",
        component: () => import("../views/resources/bookdetail.vue")
    }, {
        path: "intangible",
        meta: {
            active: 'culture_intangible',
            title: "非遗保护-荆门市群众艺术馆"
        },
        name: "culture_intangible",
        component: () => import("../views/intangible/index.vue")
    }, {
        path: "intangible/detail",
        meta: {
            active: 'culture_intangible',
            title: "非遗保护-荆门市群众艺术馆"
        },
        name: "culture_intangible_detail",
        component: () => import("../views/intangible/detail.vue")
    },
    {
        path: "resource",
        meta: {
            active: 'resource',
            title: "非遗保护-荆门市群众艺术馆"
        },
        name: "resource",
        component: () => import("../views/resource_common_view/index.vue")
    },
    {
        path: "teachvideo",
        meta: {
            active: 'culture_teachvideo',
            title: "教学视频-荆门市群众艺术馆"
        },
        name: "culture_teachvideo",
        component: () => import("../views/teachvideo/index.vue")
    }, {
        path: "teachvideo/detail",
        meta: {
            active: 'culture_teachvideo',
            title: "非遗保护-荆门市群众艺术馆"
        },
        name: "culture_teachvideo_detail",
        component: () => import("../views/teachvideo/detail.vue")
    }, {
        path: "teachvideo/edetail",
        meta: {
            active: 'culture_teachvideo',
            title: "教学视频-荆门市群众艺术馆"
        },
        name: "culture_teachvideo_edetail",
        component: () => import("../views/teachvideo/edetail.vue")
    },
    {
        path: 'search',
        name: 'search_index',
        title: '搜索-荆门市群众艺术馆',
        component: () => import("../views/search/index.vue")
    },
    {
        path: 'interact',
        meta: {
            active: 'culture_interact',
            title: '互动平台-荆门市群众艺术馆'
        },
        name: 'culture_interact',
        component: () => import("../views/interact/index.vue")
    },
    {
        path: "regulations",
        meta: {
            active: 'culture_regulations',
            title: '政策法规-荆门市群众艺术馆'
        },
        name: "culture_regulations",
        component: () => import("../views/regulations/index.vue")

    },
    {
        path: "regulations/detail",
        meta: {
            active: 'culture_regulations',
            title: '政策法规-荆门市群众艺术馆'
        },
        name: "culture_regulations_detail",
        component: () => import("../views/regulations/detail.vue")

    },




    {
        path: "cmsCommon-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-default/index.vue")
    },
    {
        path: "cmsCommon-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-default/detail.vue")
    },
    {
        path: "cmsCommon-party",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-party/index.vue")
    },
    {
        path: "cmsCommon-party/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-default/detail.vue")
    },
    {
        path: "cmsCommon-image",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-image/index.vue")
    },
    {
        path: "cmsCommon-image/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-default/detail.vue")
    },
    {
        path: "active-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/active-default/index.vue")
    },
    {
        path: "active-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/active-default/detail.vue")
    },
    {
        path: "active-offline",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/active-default/index.vue")
    },
    {
        path: "active-offline/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/active-default/detail.vue")
    },
    {
        path: "course-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/course-default/index.vue")
    },
    {
        path: "course-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/course-default/detail.vue")
    },
    {
        path: "resource-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/resource-default/index.vue")
    },
    {
        path: "resource-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/resource-default/detail.vue")
    },
    {
        path: "ebook-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/ebook-default/index.vue")
    },
    {
        path: "ebook-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/ebook-default/detail.vue")
    },
    {
        path: "live-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/live-default/index.vue")
    },
    {
        path: "live-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/live-default/detail.vue")
    },
    {
        path: "heritage-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/heritage-default/index.vue")
    },
    {
        path: "heritage-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/heritage-default/detail.vue")
    },
    {
        path: "heritage-list",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/heritage-list/index.vue")
    },
    {
        path: "heritage-list/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/heritage-default/detail.vue")
    },
    {
        path: "volunteerTeam-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/volunteerTeam-default/index.vue")
    },
    {
        path: "volunteerTeam-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/volunteerTeam-default/detail.vue")
    },
    {
        path: "volunteerActive-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/volunteerActive-default/detail.vue")
    },
    {
        path: "venue-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/venue-default/index.vue")
    },
    {
        path: "venue-default/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/venue-default/detail.vue")
    },
    {
        path: "cmsCommon-pdf",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-pdf/index.vue")
    },
    {
        path: "cmsCommon-pdf/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-pdf/detail.vue")
    },
    {
        path: "cmsCommon-list",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-list/index.vue")
    },
    {
        path: "cmsCommon-list/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/cmsCommon-default/detail.vue")
    },
    {
        path: "resource-other",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/resource-default/index.vue")
    },
    {
        path: "resource-other/detail",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/resource-default/detail.vue")
    },
    {
        path: "homepage-default",
        meta: {
            title: "荆门市群众艺术馆"
        },
        component: () => import("../views/homepage/index.vue")
    },
    ]
}, {
    path: '/login',
    name: 'login',
    component: Base,
    children: [{
        path: '',
        name: 'login',
        component: () => import('../views/login/index.vue'),
    }]
},
{
    path: '/login_callback',
    name: 'login_callback',
    component: () => import('../views/login/callback.vue')

},
{
    path: '/about',
    name: 'About',
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
},


]






const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'history',
    base: public_base,
    routes
})

router.beforeEach((to, from, next) => {
    console.log(to)
    // document.title = to.query.name
    // console.log(document.title)
    // 让页面回到顶部
    document.documentElement.scrollTop = 0
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    var toPath = to.path
    var fromPath = from.path;
    switch (toPath) {
        case '/homepage':
            B("荆门群艺馆-首页", "首页", getPageSid(), "visit")
            break;
        case '/activity':
            B("荆门群艺馆-群文活动", "群文活动", getPageSid(), "visit")
            break;
        case '/activity/detail':
            B("荆门群艺馆-活动详情", "活动详情", getPageSid(), "visit")
            break;
        case '/volunteer':
            B("荆门群艺馆-荆门群艺馆-志愿服务", "志愿服务", getPageSid(), "visit")
            break;
        case '/volunteer/detail':
            B("荆门群艺馆-志愿详情", "志愿详情", getPageSid(), "visit")
            break;
        case '/live':
            B("荆门群艺馆-直播", "直播", getPageSid(), "visit")
            break;
        case '/live/detail':
            B("荆门群艺馆-直播详情", "直播详情", getPageSid(), "visit")
            break;
        case '/map/list':
            B("荆门群艺馆-场馆预约列表", "场馆预约列表", getPageSid(), "visit")
            break;
        case '/map/detail':
            B("荆门群艺馆-场馆预约详情", "场馆预约详情", getPageSid(), "visit")
            break;
        case '/news':
            B("荆门群艺馆-资讯动态", "资讯动态", getPageSid(), "visit")
            break;
        case '/news/detail':
            B("荆门群艺馆-资讯动态详情", "资讯动态详情", getPageSid(), "visit")
            break;
        case '/resources':
            B("荆门群艺馆-数字资源", "数字资源", getPageSid(), "visit")
            break;
        case '/resources/edetail':
            B("荆门群艺馆-数字资源详情", "数字资源详情", getPageSid(), "visit")
            break;

        case '/resources/bookdetail':
            B("荆门群艺馆-电子书资源详情", "电子书资源详情", getPageSid(), "visit")
            break;
        case '/intangible':
            B("荆门群艺馆-非遗保护", "非遗保护", getPageSid(), "visit")
            break;
        case '/intangible/detail':
            B("荆门群艺馆-非遗保护详情", "非遗保护详情", getPageSid(), "visit")
            break;
        case '/search':
            B("荆门群艺馆-搜索", "搜索", getPageSid(), "visit")
            break;
        case '/search/detail':
            B("荆门群艺馆-搜索详情", "搜索详情", getPageSid(), "visit")
            break;
        case '/interact':
            B("荆门群艺馆-互动平台", "互动平台", getPageSid(), "visit")
            break;
        case '/regulations':
            B("荆门群艺馆-政策法规", "政策法规", getPageSid(), "visit")
            break;
        case '/regulations/detail':
            B("荆门群艺馆-政策法规详情", "政策法规详情", getPageSid(), "visit")
            break;
        case '/artistic':
            B("荆门群艺馆-艺术品鉴", "艺术品鉴", getPageSid(), "visit")
            break;
        case '/artistic/detail':
            B("荆门群艺馆-艺术品鉴详情", "艺术品鉴详情", getPageSid(), "visit")
            break;
        case '/artresearch':
            B("荆门群艺馆-艺术研究", "艺术研究", getPageSid(), "visit")
            break;
        case '/artresearch/detail':
            B("荆门群艺馆-艺术研究详情", "艺术研究详情", getPageSid(), "visit")
            break;
        case '/partybuilding':
            B("荆门群艺馆-党的建设", "党的建设", getPageSid(), "visit")
            break;
        case '/partybuilding/detail':
            B("荆门群艺馆-党的建设详情", "党的建设详情", getPageSid(), "visit")
            break;
        case '/login':
            B("荆门群艺馆-登录", "登录", getPageSid(), "visit")
            break;
        case '/about':
            B("荆门群艺馆-关于", "关于", getPageSid(), "visit")
            break;
        case '/cultural':
            B("荆门群艺馆-艺术培训", "艺术培训", getPageSid(), "visit")
            break;
        case '/cultural/detail':
            B("荆门群艺馆-艺术培训详情", "艺术培训详情", getPageSid(), "visit")
            break;
        case '/mine':
            B("荆门群艺馆-我的", "我的", getPageSid(), "visit")
            break;
        case '/myborrow':
            B("荆门群艺馆-我的借阅", "我的借阅", getPageSid(), "visit")
            break;
        case '/myactivity':
            B("荆门群艺馆-我的活动", "我的活动", getPageSid(), "visit")
            break;
        case '/mycollect':
            B("荆门群艺馆-我的收藏", "我的收藏", getPageSid(), "visit")
            break;
        case '/mysay':
            B("荆门群艺馆-我的留言", "我的留言", getPageSid(), "visit")
            break;
        default:
            break;
    }
    switch (fromPath) {
        case '/homepage':
            B("荆门群艺馆-荆门群艺馆-首页", "首页", getPageSid(), "leave")
            break;
        case '/activity':
            B("荆门群艺馆-群文活动", "群文活动", getPageSid(), "leave")
            break;
        case '/activity/detail':
            B("荆门群艺馆-活动详情", "活动详情", getPageSid(), "leave")
            break;
        case '/volunteer':
            B("荆门群艺馆-志愿服务", "志愿服务", getPageSid(), "leave")
            break;
        case '/volunteer/detail':
            B("荆门群艺馆-志愿详情", "志愿详情", getPageSid(), "leave")
            break;
        case '/live':
            B("荆门群艺馆-直播", "直播", getPageSid(), "leave")
            break;
        case '/live/detail':
            B("荆门群艺馆-直播详情", "直播详情", getPageSid(), "leave")
            break;
        case '/map/list':
            B("荆门群艺馆-场馆预约列表", "场馆预约列表", getPageSid(), "leave")
            break;
        case '/map/detail':
            B("荆门群艺馆-场馆预约详情", "场馆预约详情", getPageSid(), "leave")
            break;
        case '/news':
            B("荆门群艺馆-资讯动态", "资讯动态", getPageSid(), "leave")
            break;
        case '/news/detail':
            B("荆门群艺馆-资讯动态详情", "资讯动态详情", getPageSid(), "leave")
            break;
        case '/resources':
            B("荆门群艺馆-数字资源", "数字资源", getPageSid(), "leave")
            break;
        case '/resources/edetail':
            B("荆门群艺馆-数字资源详情", "数字资源详情", getPageSid(), "leave")
            break;

        case '/resources/bookdetail':
            B("荆门群艺馆-电子书资源详情", "电子书资源详情", getPageSid(), "leave")
            break;
        case '/intangible':
            B("荆门群艺馆-非遗保护", "非遗保护", getPageSid(), "leave")
            break;
        case '/intangible/detail':
            B("荆门群艺馆-非遗保护详情", "非遗保护详情", getPageSid(), "leave")
            break;
        case '/search':
            B("荆门群艺馆-搜索", "搜索", getPageSid(), "leave")
            break;
        case '/search/detail':
            B("荆门群艺馆-搜索详情", "搜索详情", getPageSid(), "leave")
            break;
        case '/interact':
            B("荆门群艺馆-互动平台", "互动平台", getPageSid(), "leave")
            break;
        case '/regulations':
            B("荆门群艺馆-政策法规", "政策法规", getPageSid(), "leave")
            break;
        case '/regulations/detail':
            B("荆门群艺馆-政策法规详情", "政策法规详情", getPageSid(), "leave")
            break;
        case '/artistic':
            B("荆门群艺馆-艺术品鉴", "艺术品鉴", getPageSid(), "leave")
            break;
        case '/artistic/detail':
            B("荆门群艺馆-艺术品鉴详情", "艺术品鉴详情", getPageSid(), "leave")
            break;
        case '/artresearch':
            B("荆门群艺馆-艺术研究", "艺术研究", getPageSid(), "leave")
            break;
        case '/artresearch/detail':
            B("荆门群艺馆-艺术研究详情", "艺术研究详情", getPageSid(), "leave")
            break;
        case '/partybuilding':
            B("荆门群艺馆-党的建设", "党的建设", getPageSid(), "leave")
            break;
        case '/partybuilding/detail':
            B("荆门群艺馆-党的建设详情", "党的建设详情", getPageSid(), "leave")
            break;
        case '/login':
            B("荆门群艺馆-登录", "登录", getPageSid(), "leave")
            break;
        case '/about':
            B("荆门群艺馆-关于", "关于", getPageSid(), "leave")
            break;
        case '/cultural':
            B("荆门群艺馆-艺术培训", "艺术培训", getPageSid(), "leave")
            break;
        case '/cultural/detail':
            B("荆门群艺馆-艺术培训详情", "艺术培训详情", getPageSid(), "leave")
            break;
        case '/mine':
            B("荆门群艺馆-我的", "我的", getPageSid(), "leave")
            break;
        case '/myborrow':
            B("荆门群艺馆-我的借阅", "我的借阅", getPageSid(), "leave")
            break;
        case '/myactivity':
            B("荆门群艺馆-我的活动", "我的活动", getPageSid(), "leave")
            break;
        case '/mycollect':
            B("荆门群艺馆-我的收藏", "我的收藏", getPageSid(), "leave")
            break;
        case '/mysay':
            B("荆门群艺馆-我的留言", "我的留言", getPageSid(), "leave")
            break;
        default:
            break;
    }
    next()
})
export default router
