import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    info:[
    ]
  },
  mutations: {
    getInfo(state,param) {
      state.info = param
      console.log(state.info);
    }
  },
  actions: {
  },
  modules: {
    user
  }
})
