import request from '@/utils/request'

// export function navList(data) {
//     return request({
//         url: '/v2/culture/index/nav',
//         method: 'post',
//         params: data
//     })
// }
export function navList(data) {
    return request({
        url: '/v2/cms/nav/list?app_type=1',
        method: 'post',
        params: data
    })
}

export function navChildList(data) {
    return request({
        url: 'v2/cms/nav/getchildrennav',
        method: 'post',
        params: data
    })
}

//获取接口信息
export function infoList(data){
    return request({
        url: '/v2/cms/article/getinfo?app_type=1',
        method: 'post',
        params: data
    })
}
/**
 * 获取导航详情
 * @param {*} data
 */
 export function navinfo(data) {
    return request({
        url: '/v2/cms/nav/detail',
        method: 'post',
        params: data
    })
}