import request from "@/utils/request";
// var loadJs = function(url, callback) {
//   var head = document.getElementsByTagName("head")[0];
//   var script = document.createElement("script");

//   script.src = url;
//   script.async = false;
//   var done = false;
//   script.onload = script.onreadystatechange = function() {
//     if (
//       !done &&
//       (!this.readyState ||
//         this.readyState == "loaded" ||
//         this.readyState == "complete")
//     ) {
//       //	        if (!done && (this.readyState == "loaded" || this.readyState == "complete")) {
//       done = true;
//       callback();
//       script.async = false;
//       script.onload = script.onreadystatechange = null;
//       head.removeChild(script);
//     }
//   };
//   head.appendChild(script);
// };

// function v(a) {
//   var c = {},
//     e;
//   if (void 0 === a) {
//     var b = window.location;
//     a = b.host;
//     var t = b.pathname;
//     var k = b.search.substr(1);
//     var d = b.hash;
//   } else
//     (b =
//       a.match(
//         /\w+:\/\/((?:[\w-]+\.)+\w+)(?::\d+)?(\/[^\?\\"'\|:<>]*)?(?:\?([^'"\\<>#]*))?(?:#(\w+))?/i
//       ) || []),
//       (a = b[1]),
//       (t = b[2]),
//       (k = b[3]),
//       (d = b[4]);
//   void 0 !== d && (d = d.replace(/"|'|<|>/gi, "M"));
//   k &&
//     (function() {
//       for (var f = k.split("&"), l = 0, h = f.length; l < h; l++)
//         if (-1 != f[l].indexOf("=")) {
//           var g = f[l].indexOf("="),
//             p = f[l].slice(0, g);
//           g = f[l].slice(g + 1);
//           c[p] = g;
//         }
//     })();
//   k = (function() {
//     if ("undefined" === typeof k) return k;
//     for (var f = k.split("&"), l = [], h = 0, g = f.length; h < g; h++)
//       if (-1 != f[h].indexOf("=")) {
//         var p = f[h].indexOf("="),
//           q = f[h].slice(0, p);
//         p = f[h].slice(p + 1);
//         (m.ignoreParams && -1 != m.ignoreParams.indexOf(q)) ||
//           l.push(q + "=" + p);
//       }
//     return l.join("&");
//   })();
//   d &&
//     (function() {
//       for (
//         var f = 0 == d.indexOf("#") ? d.substr(1).split("&") : d.split("&"),
//           l = 0,
//           h = f.length;
//         l < h;
//         l++
//       )
//         if (-1 != f[l].indexOf("=")) {
//           var g = f[l].indexOf("="),
//             p = f[l].slice(0, g);
//           g = f[l].slice(g + 1);
//           if ("adtag" === p.toLowerCase()) {
//             e = g;
//             break;
//           }
//         }
//     })();
//   return {
//     host: a,
//     path: t,
//     search: k,
//     hash: d,
//     param: c,
//     adtag: e,
//   };
// }
function n(a) {
  for (var c = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], e = 10; 1 < e; e--) {
    var b = Math.floor(10 * Math.random()),
      t = c[b];
    c[b] = c[e - 1];
    c[e - 1] = t;
  }
  for (e = b = 0; 5 > e; e++) b = 10 * b + c[e];
  return (a || "") + (b + "" + +new Date());
}
function getUuid() {
  return localStorage.getItem("bookgoal_uuid");
}
function setUuid() {
  var a = n();
  localStorage.setItem("bookgoal_uuid", a);
  return a;
}
//  获取基本参数  对象c
function x(showModule = "-", showTitle = "", page_sid, sub_task) {
  var c = {
    page: window.location.href,
    task: "front_action",
    uuid: "",
    log_time: "",
    sub_task: sub_task,
    src: "web",
    instance: "",
    module: "",
    title: "",
    biz_id: "",
    puid: page_sid,
    app_id: "official",
  };
  c.uuid = (function () {
    var b = getUuid();
    b || (b = setUuid());
    return b;
  })();
  c.log_time = getNowDate();
  if (!c.module && showModule) {
    c.module = showModule;
  }
  if (!c.title) {
    c.title = showTitle ? showTitle : document.title;
  }
  c.biz_id = process.env.VUE_APP_AID
  return c;
}
//  获取screen
function y() {
  try {
    // var a = navigator,
    var c = screen || {
      width: "",
      height: "",
      colorDepth: "",
    },
      e = {
        screen: c.width + "x" + c.height,
      };
  } catch (b) {
    return {};
  }
  return e;
}
//  时间格式化
function getNowDate() {
  var d = new Date();
  var year = d.getFullYear();
  var month = change(d.getMonth() + 1);
  var day = change(d.getDate());
  var hour = change(d.getHours());
  var minute = change(d.getMinutes());
  var second = change(d.getSeconds());
  function change(t) {
    if (t < 10) {
      return "0" + t;
    } else {
      return t;
    }
  }
  return (
    year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
  );
}


//  整合参数
function k(showModule, showTitle, page_sid, sub_task) {
  var c = x(showModule, showTitle, page_sid, sub_task);
  var m = y();
  var { ...formData } = { ...c, ...m };
  return formData;
}

export function B(showModule, showTitle, page_sid, sub_task) {
  var data = k(showModule, showTitle, page_sid, sub_task);
  //   var d = "http://2016.bookgo.com.cn/v2/devops/logger/track";
  var d = "/v2/devops/logger/track";
  request({
    url: d,
    method: "GET",
    params: data,
  });
}

// 获取系统版本
// function getOsInfo() {
//   let userAgent = window.navigator.userAgent.toLowerCase();
//   let version = "";
//   if (userAgent.indexOf("win") > -1) {
//     if (
//       userAgent.indexOf("windows nt 5.0") > -1 ||
//       userAgent.indexOf("Windows 2000") > -1
//     ) {
//       version = "Windows 2000";
//     } else if (
//       userAgent.indexOf("windows nt 5.1") > -1 ||
//       userAgent.indexOf("Windows XP") > -1
//     ) {
//       version = "Windows XP";
//     } else if (
//       userAgent.indexOf("windows nt 5.2") > -1 ||
//       userAgent.indexOf("Windows 2003") > -1
//     ) {
//       version = "Windows 2003";
//     } else if (
//       userAgent.indexOf("windows nt 6.0") > -1 ||
//       userAgent.indexOf("Windows Vista") > -1
//     ) {
//       version = "Windows Vista";
//     } else if (
//       userAgent.indexOf("windows nt 6.1") > -1 ||
//       userAgent.indexOf("windows 7") > -1
//     ) {
//       version = "Windows 7";
//     } else if (
//       userAgent.indexOf("windows nt 6.2") > -1 ||
//       userAgent.indexOf("windows 8") > -1
//     ) {
//       version = "Windows 8";
//     } else if (userAgent.indexOf("windows nt 6.3") > -1) {
//       version = "Windows 8.1";
//     } else if (
//       userAgent.indexOf("windows nt 6.4") > -1 ||
//       userAgent.indexOf("windows nt 10") > -1
//     ) {
//       version = "Windows 10";
//     } else {
//       version = "Unknown";
//     }
//   } else if (userAgent.indexOf("iphone") > -1) {
//     version = "Iphone";
//   } else if (userAgent.indexOf("mac") > -1) {
//     version = "Mac";
//   } else if (
//     userAgent.indexOf("x11") > -1 ||
//     userAgent.indexOf("unix") > -1 ||
//     userAgent.indexOf("sunname") > -1 ||
//     userAgent.indexOf("bsd") > -1
//   ) {
//     version = "Unix";
//   } else if (userAgent.indexOf("linux") > -1) {
//     if (userAgent.indexOf("android") > -1) {
//       version = "Android";
//     } else {
//       version = "Linux";
//     }
//   } else {
//     version = "Unknown";
//   }
//   return version;
// }

// 捕获page_sid
export function getPageSid() {
  var n = Math.floor(10000 * Math.random() + 1000) + "" + new Date().getTime();
  return n;
}
