<template>
  <div>
    <Header @getSearchText="searchText = $event" @getNavData="commonNavData"></Header>
    <router-view :searchText="searchText" :nav="nav" ref="child"/>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
export default {
  name: "Base",
  components: { Header, Footer },
  data() {
    return {
      searchText: "",
      nav:[]
    };
  },
  methods:{
    commonNavData(val) {
      console.log('xxxxxxxxxxxxxxx')
      console.log(val)
      this.nav = val
    }
  }
};
</script>

<style lang="" scoped></style>
