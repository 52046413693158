<template>
  <div class="footer-index-bg">
    <div class="footer-index">
      <div class="footer-index-top">
        <div class="footer-index-top-link">
          <div class="footer-index-top-link-top clear">
            <div class="footer-index-top-link-top-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/web_culture/footer/footer_link.png"
                alt=""
              />
            </div>
            <div class="footer-index-top-link-top-title">友情链接</div>
          </div>
          <ul class="footer-index-top-link-ul">
            <li class="footer-index-top-link-ul-li clear">
              <template v-for="(item, index) in flink_list">
                <div
                  class="footer-index-top-link-ul-li-link"
                  :style="{
                    'line-height': '.26rem',
                    border: index + 1 == flink_list.length ? 'none' : '',
                  }"
                >
                  <a :href="item.url" style="color:white;" target="_blank">
                    {{ item.title }}</a
                  >
                </div>
              </template>
            </li>
          </ul>
        </div>
        <div class="footer-index-top-contact">
          <div class="footer-index-top-contact-top">
            <div class="footer-index-top-contact-top-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/web_culture/footer/footer_contact.png"
                alt=""
              />
            </div>
            <div class="footer-index-top-contact-top-title">联系我们</div>
          </div>
          <ul class="footer-index-top-contact-ul">
            <li class="footer-index-top-contact-ul-li">
              地址：荆门市象山一路九号
            </li>
            <li class="footer-index-top-contact-ul-li">电话：0724-2332328</li>
            <!-- <li
              class="footer-index-top-contact-ul-li footer-index-top-contact-ul-li-line"
            >
              服务时间：周一到周五-上午8:30～12:00
              下午14:30～17:30（法定节假日除外）
            </li> -->
          </ul>
        </div>
        <div class="footer-index-top-attention">
          <div class="footer-index-top-attention-top">
            <div class="footer-index-top-attention-top-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/web_culture/footer/footer_attention_icon.png"
                alt=""
              />
            </div>
            <div class="footer-index-top-attention-top-title">关注我们</div>
          </div>
          <ul class="footer-index-top-attention-ul">
            <li class="footer-index-top-attention-ul-li">
              <img
                style="width: 0.88rem; height: 0.88rem"
                src="https://image.bookgo.com.cn/webculture/jm/footer/footer-index-followus.png"
                alt=""
              />
            </li>
          </ul>
        </div>
        <!-- <div class="footer-index-top-statistics">
          <div class="footer-index-top-statistics-top">
            <div class="footer-index-top-statistics-top-icon">
              <img
                style="width: 100%; height: 100%"
                src="https://image.bookgo.com.cn/web_culture/footer/footer_statistics.png"
                alt=""
              />
            </div>
            <div class="footer-index-top-statistics-top-title">统计访问量</div>
          </div>
          <ul class="footer-index-top-statistics-ul">
            <li class="footer-index-top-statistics-ul-li">总访问量：{{visited.total}}</li>
            <li class="footer-index-top-statistics-ul-li">今日访问量：{{visited.today}}</li>
          </ul>
        </div> -->
      </div>
      <div class="footer-index-bottom">
        <div class="jmqyg">版权所有：荆门市群众艺术馆</div>
        <span>Copyright@http://jmsqyg.cn  all rights reserved</span>
        <div class="divLine"></div>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color:#fff">鄂ICP备11017664号-2</a>
      </div>
    </div>
  </div>
</template>
<script>
import { flinkList, getVisit } from "@/api/common";
export default {
  data() {
    return {
      aid: process.env.VUE_APP_AID,
      flink_list: [],
      visited: {},
    };
  },
  mounted() {
    this.getFlinks();
    this.getOrgVisit();
  },
  methods: {
    getFlinks() {
      flinkList({ aid: this.aid }).then((res) => {
        if (res.code == 0) {
          this.flink_list = res.data;
        }
      });
    },
    getOrgVisit() {
      getVisit(this.aid).then((res) => {
        if (res.code == 0) {
          this.visited = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/footer.scss";
</style>
