import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/static/xs_culture/css/common.css'
import * as filters from './filters' // global filters

Vue.use(ElementUI);
Vue.config.productionTip = false

import '@/permission' // permission control

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
  ak: 'VX8srP4mLw0EuaFuxcuG2EZNaSbGHz8z'
})

import moment from 'moment';
Vue.filter('dateFormat',(dateStr,pattern='YYYY-MM-DD HH:mm:ss')=>{
  return moment(dateStr).format(pattern)
})
Vue.filter('dateFormat1',(dateStr,pattern='YYYY-MM-DD HH:mm')=>{
  return moment(dateStr).format(pattern)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')