import { getToken, getUserInfo } from "@/utils/auth.js"

const userInfo = getUserInfo();
const access_token = getToken();
const state = () => {

    if (userInfo) {
        return {
            nickname: userInfo.nickname,
            headimgurl: userInfo.headimgurl,
            mobile_number: userInfo.mobile_number,
            email: userInfo.email,
            realname: userInfo.realname,
            id: userInfo.id,
            access_token: access_token,
            province: userInfo.province,
            city :userInfo.city
        }
    }

    return {
        nickname: '',
        headimgurl: '',
        mobile_number: '',
        province: '',
        city: '',
        email: '',
        realname: '',
        id: 0,
        access_token: access_token
    }

}

const mutations = {
    userInit: (state, userInfo) => {

        state.nickname = userInfo.nickname
        state.headimgurl = userInfo.headimgurl
        state.realname = userInfo.realname
        state.email = userInfo.email
        state.id = userInfo.id
        state.access_token = userInfo.access_token
        state.mobile_number = userInfo.mobile_number
        state.province = userInfo.province
        state.city = userInfo.city

    }
}

const actions = {
    userInitAction({ commit }) {
        commit('userInit', getUserInfo())
    }
}

const user = {
    namespaced: true,

    state,
    mutations,
    actions
}

export default user