import Vue from 'vue'
import Storage from 'vue-ls';

const options = {
    namespace: '', // key键前缀
    name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
    storage: 'local', // 存储名称: session, local, memory
};
Vue.use(Storage, options);

const redirectKey = "redirect_url"

export function getRedirect() {
   return Vue.ls.get(redirectKey, '/');
}
export function setRedirect(redirect) {
    Vue.ls.set(redirectKey, redirect, 60 * 60 * 12000); //有效12小时
}

/**
 * 获取微信登录地址
 * @param {*} redirect 
 */
export function getWechatLoginUrl(redirect) {
    setRedirect(redirect);
    const aid = process.env.VUE_APP_AID
    return `${process.env.VUE_APP_LOGIN_HOST}/v2/user/h5auth/wxQrLogin?get_token=1&aid=${aid}&redirect_url=`+ window.location.origin+'/login_callback';
}

